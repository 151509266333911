import * as React from 'react';
import { FormTitle, FieldList, FieldItem, FieldInfo, TextField, FormFooter, Checkbox } from './form';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./foi-review-form.json');

const FOIReviewFormStep7 = (props) => {
  const {
    formData,
    formErrors,
    onChange,
    onPrevious,
    onSubmit,
  } = props;
  
  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[6]}
      />
      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.declaration}
          />
          <Checkbox 
            label={globalConfig.declarations[0]}
            name="declaration1"
            value={formData.declaration1}
            error={formErrors.declaration1}
            required={true}
            onChange={onChange}
          />
          <Checkbox 
            label={globalConfig.declarations[1]}
            name="declaration2"
            value={formData.declaration2}
            error={formErrors.declaration2}
            required={true}
            onChange={onChange}
          />
        </FieldItem>
        <FieldItem>
          <TextField 
            type="text"
            label="Enter the characters shown"
            name="captcha"
            error={formErrors.captcha}
            required={true}
            captcha={true}
          />
        </FieldItem>
      </FieldList>
      <FormFooter 
        next="Submit Review"
        onPrevious={onPrevious}
      />
    </form>
  )
}

export default FOIReviewFormStep7;
