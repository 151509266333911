import * as React from 'react';
import { ShowIf } from './helpers';
import { Container } from './grid';
import OVICForm from './ovic-form';
import FormHeader from './form-header';
import FormError from './form-error';
import FormLoading from './form-loading';
import FOIReviewFormStep1 from './foi-review-form-step-1';
import FOIReviewFormStep2 from './foi-review-form-step-2';
import FOIReviewFormStep3 from './foi-review-form-step-3';
import FOIReviewFormStep4 from './foi-review-form-step-4';
import FOIReviewFormStep5 from './foi-review-form-step-5';
import FOIReviewFormStep6 from './foi-review-form-step-6';
import FOIReviewFormStep7 from './foi-review-form-step-7';
import FOIReviewFormStep8 from './foi-review-form-step-8';
import * as styles from './form.module.scss';
const formConfig = require('./foi-review-form.json');

class FOIReviewForm extends OVICForm 
{
  constructor(props) {
    super(props);

    this.state = {
      form: 'foireview',
      step: 1,
      steps: 8,
      files: [],
      loading: false,
      percentLoaded: 0,
      formData: {
        publicationConfirmation: '',
        areYouApplyingAgency: '',
        areYouApplyingTimeframe: '',
        decisionDate: '',
        decisionType: '',
        additionalInformation: '',
        editedCopy: '',
        personalAffairs: '',
        personalAffairsDetails: '',
        agencyCopy: '',
        timeExtension: '',
        contactRepresentative: '',
        representativeTitle: '',
        representativeFirstName: '',
        representativeSurname: '',
        representativeOrganisation: '',
        representativeRelationship: '',
        representativeAuthority: '',
        contactTitle: '',
        contactFirstName: '',
        contactSurname: '',
        contactOrganisation: '',
        contactPhone: '',
        contactEmail: '',
        contactAddress: '',
        requestAgency: '',
        requestAgencyReference: '',
        requestAgencyContact: '',
        requestAgencyDate: '',
        requestAgencyDateReceived: '',
        attachmentRequest: '',
        attachmentDecision: '',
        attachmentCorrespondence: '',
        attachmentOther: '',
        attachmentOtherDescription: '',
        declaration1: '',
        declaration2: '',
      },
      formErrors: {
      },
    }
  }

  render() {
    return (
      <div 
        className={styles.root}
        ref={this.rootRef}>
        <FormHeader
          title={formConfig.title}
          steps={formConfig.steps}
          step={this.state.step}
        />
        <Container>
          <FOIReviewFormStep1 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIReviewFormStep2 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIReviewFormStep3 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIReviewFormStep4 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIReviewFormStep5
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIReviewFormStep6
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIReviewFormStep7
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIReviewFormStep8
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
        </Container>
        <ShowIf cond={this.state.loading}>
          <FormLoading 
            percent={this.state.percentLoaded}
          />
        </ShowIf>
        <ShowIf cond={this.state.submitError}>
          <FormError>
            <p>This form could no be submitted due to a system error.</p>
          </FormError>
        </ShowIf>
      </div>
    )
  }
}

export default FOIReviewForm;
