import * as React from 'react';
import { FormTitle, FieldList, FieldItem, FieldInfo, FormFooter, Checkbox } from './form';
import * as styles from './form.module.scss';
const formConfig = require('./foi-review-form.json');

const FOIReviewFormStep2 = (props) => {
  const {
    formData, 
    formErrors,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[1]}
      />
      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.publicationOfDecisions}
          />
          <Checkbox 
            label="I understand that a de-identified copy of the review decision may be published online."
            name="publicationConfirmation"
            value={formData.publicationConfirmation}
            error={formErrors.publicationConfirmation}
            required={true}
            onChange={onChange}
          />
        </FieldItem>
      </FieldList>
      <FormFooter 
        next="Next: Details about the agency or Minister"
        onPrevious={onPrevious}
      />
    </form>
  )
}

export default FOIReviewFormStep2;
