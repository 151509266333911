import * as React from 'react';
import { ShowIf } from './helpers';
import { FormTitle, FieldList, FieldItem, FieldInfo, FormFooter, TextField, NoYesGroup } from './form';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./foi-review-form.json');

const FOIReviewFormStep3 = (props) => {
  const {
    formData, 
    formErrors,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>

      <FormTitle 
        text={formConfig.steps[2]}
      />

      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.areYouApplyingAgency}
          />
          <NoYesGroup 
            name="areYouApplyingAgency"
            value={formData.areYouApplyingAgency}
            error={formErrors.areYouApplyingAgency}
            required={true}
            onChange={onChange}
          />
        </FieldItem>
      </FieldList>

      <ShowIf cond={formData.areYouApplyingAgency === 'No'}>
        <FieldInfo>
          <p>Unfortunately we are unable to assist you. <a href="https://ovic.vic.gov.au/freedom-of-information/for-the-public/foi-reviews/#who-else-might-be-able-to-help-me" target="_blank" rel="noopener noreferrer">Find information on other organisations  that may be able to assist you.</a></p><br/><br/>
        </FieldInfo>
      </ShowIf>

      <ShowIf cond={formData.areYouApplyingAgency === 'Yes'}>
        <FieldList>
          <FieldItem>
            <FieldInfo 
              {...formConfig.agencyDetails}
            />
            <TextField 
              type="text"
              label="Agency or Minister’s name:"
              name="requestAgency"
              value={formData.requestAgency}
              error={formErrors.requestAgency}
              required={true}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.short,
              }}
            />
          </FieldItem>
          <FieldItem>
            <FieldInfo 
              {...formConfig.agencyReference}
            />
            <TextField 
              type="text"
              label="Their reference number:"
              name="requestAgencyReference"
              value={formData.requestAgencyReference}
              error={formErrors.requestAgencyReference}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.short,
              }}
            />
          </FieldItem>
        </FieldList>
        <FormFooter 
          next="Next: Review details"
          onPrevious={onPrevious}
        />
      </ShowIf>
      
    </form>
  )
}

export default FOIReviewFormStep3;
