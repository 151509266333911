import * as React from 'react';
import { ShowIf } from './helpers';
import { FormTitle, FieldList, FieldItem, FieldInfo, FormFooter, TextField, FieldSet, Checkbox } from './form';
import Uploader from './uploader';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./foi-review-form.json');

const FOIReviewFormStep6 = (props) => {
  const {
    formData,
    formErrors,
    onUpdateFiles,
    onChange,
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[5]}
      />
      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.attachments}
          />
          <FieldSet>
            <Checkbox 
              label="A copy of the request that was made to the agency or Minister."
              name="attachmentRequest"
              value={formData.attachmentRequest}
              error={formErrors.attachmentRequest}
              onChange={onChange}
            />
            <Checkbox 
              label="A copy of the agency or Minister’s decision letter."
              name="attachmentDecision"
              value={formData.attachmentDecision}
              error={formErrors.attachmentDecision}
              onChange={onChange}
            />
            <Checkbox 
              label="Correspondence sent to and from the agency or Minister."
              name="attachmentCorrespondence"
              value={formData.attachmentCorrespondence}
              error={formErrors.attachmentCorrespondence}
              onChange={onChange}
            />
            <Checkbox 
              label="Other"
              name="attachmentOther"
              value={formData.attachmentOther}
              error={formErrors.attachmentOther}
              onChange={onChange}
            />
            <ShowIf cond={formData.attachmentOther !== ''}>
              <TextField 
                type="text"
                label="Other attachment details"
                name="attachmentOtherDescription"
                value={formData.attachmentOtherDescription}
                error={formErrors.attachmentOtherDescription}
                onChange={onChange}
              />
            </ShowIf>
          </FieldSet>
        </FieldItem>
        <FieldItem>
          <Uploader 
            maxFiles={globalConfig.uploads.maxFiles}
            maxSize={globalConfig.uploads.maxSize}
            fileTypes={globalConfig.uploads.fileTypes}
            fileTypesText={globalConfig.uploads.fileTypesText}
            onUpdateFiles={onUpdateFiles}
          />
        </FieldItem>
      </FieldList>
      <FormFooter 
        next="Next: Declaration"
        onPrevious={onPrevious}
      />
    </form>
  )
}

export default FOIReviewFormStep6;
